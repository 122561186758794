export const pageTitle = 'Banery reklamowe standard';
export const pageDescription = 'Drukujemy profesjonalne banery reklamowe popularną metodą druku solwentowego. Oferujemy tanie, a jednocześnie trwałe wydruki jednostronne oraz dwustronne.';
export const mainHeader = 'Baner reklamowy standard';
export const galleryTitle = 'Baner standard';

export const bannerInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**Baner reklamowy <span class="orange">czas realizacji</span>**
      \n 2-3 dni robocze`,
    },
    {
      article: `**Baner reklamowy <span class="orange">koszt wysyłki</span>**
      \n 30,00 zł/netto. W jednej paczce zmieści się ok. 30m2 banera.`,
    },
    {
      article: `**Baner <span class="orange">wymiar</span>**
      \n Maksymalna możliwa szerokość banera w jednym kawałku to 500cm. Przy większych formatach banery są łączone z brytów. 
      \n Najczęściej zamawiane wymiary banerów standardowych to:
      \n * baner 2 x 1 m
      \n * baner 2 x 0,5 m
      \n * baner 1,5 x 1 m
      \n * baner 3 x 2 m
      \n * baner 3 x 1,5 m
      \n * baner 2 x 1,5 m
      `,
    },
  ],
  rightColumn: [
    {
      article: `**Baner reklamowy <span class="orange">opis</span>**
      \n Jest to materiał typu Frontlite, stworzony specjalnie z myślą o banerach reklamowych które muszą być odporne na trudne warunki atmosferyczne. Wykonany jest on z włókien materiału które są ułożone w siatkę, a następnie włókna te zalaminowane są folią PCV o białej powierzchni matowej lub błyszczącej  przystosowany do nadruku jednostronnego. Laminowany nie oznacza że materiał jest dodatkowo zalaminowany po zadrukowaniu. Jest to jeden z procesów przy tworzeniu nośnika.
      \n Materiał o gramaturze 510g/m2 i przeplotem 500x500.
      \n Kolorystyka: CMYK.
      \n Rozdzielczość zadruku: 720 dpi.
      \n Wydruk wykończony poprzez zgrzanie krawędzi i zaoczkowanie co 50cm.
      Na życzenie Klienta jest możliwość dodatkowego gęstszego oczkowania.
      \n Gotowy **baner reklamowy** można zamontować poprzez oczka przy użyciu np. opasek zaciskowych tzw. trytytek czy linek/gum. Możliwe jest również wykończenie zadrukowanego banera tzw. tunelami, w które można wsunąć metalowe profile, drewniane kołki czy przeciągnąć linkę.
      \n Przeznaczony do średniookresowej ekspozycji reklamy. Średnia żywotność banera wynosi do kilkunastu miesięcy, a znaczącym czynnikiem warunkującym trwałość jest siła wiatru na jaką narażona jest reklama, dlatego w miejscach gdzie medium narażone jest na silne podmuchy sugeruje się aby korzystać z materiałów powlekanych lub z perforowanych siatek mesh.
      \n Druk wykonywany jest najtrwalszą techniką druku solwentowego tj. twardy solwent. Jego trwałość szacuje się na ok. kilka lat. Jest on odporny na czynniki atmosferyczne, w tym także promieniowanie UV. Do zalet tego rodzaju druku, zalicza się brak konieczności laminowania wydruków. Ponadto, cechuje go odporność na odbarwienia, wodę i ścieranie.
      \n Reklama na tego rodzaju banerze jest jednym z najtańszych sposobów na zareklamowanie swoich towarów, usług lub marki.
      `,
    },
    {
      article: `**Baner reklamowy <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
