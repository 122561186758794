/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */

/* Plugins */
import React from 'react';
import { graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Components */
import {
  SEO,
  Container,
  Heading,
  Section,
  Article,
  PriceList,
  ProductGallery,
} from '../../components/components';

/* Styles */
import {
  StyledColumnsWrapper,
  StyledLeftColumn,
  StyledRightColumn,
  StyledWideColumn,
} from '../../components/Semantic/Section/Section.styled';

/* Messages */
import {
  mainHeader, bannerInfo, pageTitle, galleryTitle, pageDescription,
} from '../../messages/banery/baner-standard.messages';
import { commonText, priceListHint, slogan } from '../../messages/banery/common.messages';

const BannerStandard = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => ({
    ...node.childImageSharp,
    thumbAlt: node.name,
  }));

  return (
    <>
      <SEO title={pageTitle} description={pageDescription} />
      <Container subPage>
        <Heading headingLevel="h1" title={mainHeader} centered />
        <Section iconSrc={data.file.childImageSharp.gatsbyImageData} iconAlt={mainHeader}>
          <StyledColumnsWrapper>
            <StyledLeftColumn>
              <PriceList title="Baner" range="Cennik wszystkich produktów!A1:B7" hint={priceListHint} />
              <ProductGallery title={`${galleryTitle} zdjęcia`} images={images} />
              {bannerInfo.leftColumn.map(({ article }) => (
                <Article key={article}>
                  <Markdown rehypePlugins={[rehypeRaw]} children={article} />
                </Article>
              ))}
            </StyledLeftColumn>
            <StyledRightColumn>
              {bannerInfo.rightColumn.map(({ article }) => (
                <Article key={article}>
                  <Markdown rehypePlugins={[rehypeRaw]} children={article} />
                </Article>
              ))}
              <AnchorLink
                to="/projekty-graficzne#cennik"
                title="Cennik projektów graficznych"
                className="anchor-link"
              />
            </StyledRightColumn>
          </StyledColumnsWrapper>

          <StyledWideColumn>
            <Markdown rehypePlugins={[rehypeRaw]} children={slogan} />
          </StyledWideColumn>
        </Section>

        {commonText.map(({ article }) => (
          <Section key={article} id="chuj">
            <Article>
              <Markdown rehypePlugins={[rehypeRaw]} children={article} />
            </Article>
          </Section>
        ))}
      </Container>
    </>
  );
};

export const query = graphql`
  query {
    file(name: {regex: "/baner-standard/"}) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
    allFile(filter: {relativeDirectory: {eq: "gallery/banery/baner-standard"}}, sort: { fields: name }) {
      edges {
        node {
          name
          childImageSharp {
            thumb: gatsbyImageData(placeholder: BLURRED)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default BannerStandard;
